import { User } from './../models/user.models';
import { ApiService } from './api.service';
import { LocalStorageService } from './localstorage.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
	public static readonly AUTH='userData';
	public currentUserSubject: BehaviorSubject<any>;
	public currentUser: Observable<any>;
	constructor(
		private api: ApiService,
		private localstorage: LocalStorageService,
		private router: Router,
		private http:HttpService
	) {
		this.currentUserSubject=new BehaviorSubject<User>(localstorage.get(AuthService.AUTH));
		this.currentUser=this.currentUserSubject.asObservable();
	}

	public get isAuthenticated(): boolean {
		return this.currentUserSubject.value!=null;
	}

	public get isPaid(): boolean {
		this.currentUserSubject.subscribe(res =>{
			if(res.paid === 1){
				return true;
			}
		});
		return false;
	}
	public get currentUserValue(): any {
		return this.currentUserSubject.value;
	}
	public setPaid(user){
		this.setUser(user);
	}
	private setUser(user:User) {
		this.localstorage.set(AuthService.AUTH, user);
		this.currentUserSubject.next(user);
	}
	private getToken(){
		if(this.currentUserSubject.value.JWT){
			return this.currentUserSubject.value.JWT;
		}else{
			return null;
		}
	}
	//register then login right away!
	async register(params): Promise<any> {
		// return await this.api.post('auth/register', params);
		return new Promise((resolve, reject) => {
			this.api.post('auth/register', params).then(res=> {
				this.setUser(res);
				resolve(res);
			});
		});
	}
	async login(params): Promise<any> {
		return new Promise((resolve, reject) => {
			this.api.post('auth/login', params).then(res => {
				if(!res.redirect_to_otp) {
					this.setUser(res);
				}
				resolve(res);
			}, err=> {
				reject(err);
			});
		});
	}
	async forgot(params): Promise<any> {
		return await this.api.post('auth/forgot', params);
	}
	async reset_validate(params): Promise<any> {
		return await this.api.post('auth/reset/validate', params);
	}
	async reset(params): Promise<any> {
		//this.setUser(res);
		return await this.api.post('auth/reset', params);
	}
	async verify(params): Promise<any> {
		return new Promise((resolve, reject) => {
			this.api.post('auth/register/verify', params).then(res=> {
				this.setUser(res);
				resolve(res);
			});
		});
	}
	async new_code(params): Promise<any> {
		return await this.api.post('auth/register/new_code', params);
	}
	async refreshUser(): Promise<any> {
		if (this.isAuthenticated) {
			const res=await this.api.get('user');
			if (!res) {
				this.logout();
				return;
			}
			else {
				let JWT = this.currentUserSubject.value.JWT;
				res.JWT = JWT;
				this.setUser(res);
				return res;
			}
		}
	}
	logout() {
		// remove user from local storage to log user out
		this.localstorage.delete(AuthService.AUTH);
		this.currentUserSubject.next(null);
		this.router.navigate(['account/auth/login']);
	}

	getReports(postData: any): Observable<any> {
		return this.http.post('/getReports', postData, this.getToken());
	}
	deleteReport(postData: any): Observable<any> {
		return this.http.post('/deleteReport', postData, this.getToken());
	}
	deleteSign(postData: any): Observable<any> {
		return this.http.post('/deleteSign', postData, this.getToken());
	}
	getOccasions(postData: any): Observable<any> {
		return this.http.post('/getOccasions', postData, this.getToken());
	}
	addOccasion(postData: any): Observable<any> {
		return this.http.post('/addOccasion', postData, this.getToken());
	}
	deleteOccasion(postData: any): Observable<any> {
		return this.http.post('/deleteOccasion', postData, this.getToken());
	}
	addSign(postData: any): Observable<any> {
		return this.http.post('/addSign', postData, this.getToken());
	}
	getSigns(postData: any): Observable<any> {
		return this.http.post('/getSigns', postData, this.getToken());
	}
	payment(postData: any): Observable<any> {
		return this.http.post('/payment', postData);
	}
	verifyPayment(): Observable<any> {
		return this.http.post('/verifyPayment', null);
	}
	getSubjects(postData: any): Observable<any> {
		return this.http.post('/getSubjects', postData, this.getToken());
	}
	addClass(postData: any): Observable<any> {
		return this.http.post('/addClass', postData, this.getToken());
	}
	editClass(postData: any): Observable<any> {
		return this.http.post('/editClass', postData, this.getToken());
	}
	deleteClass(postData: any): Observable<any> {
		return this.http.post('/deleteClass', postData, this.getToken());
	}
	getClasses(postData: any): Observable<any> {
		return this.http.post('/getClasses', postData, this.getToken());
	}
	getwindowsMenu(postData: any): Observable<any> {
		return this.http.post('/getwindowsMenu', postData, this.getToken());
	}
}