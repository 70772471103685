import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  isPaid = false;
  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.auth.verifyPayment().subscribe( res => {
      if(res.data){
        this.auth.setPaid(res.user);
        this.isPaid = true
      }
    })
  }

}
