<div class="container-fluid">
    <div class="row">


        <div class="col-lg-4" *ngIf="!isPaid">
            <div class="card border border-danger">
                <div class="card-header bg-transparent border-danger">
                    <h5 class="my-0 text-danger"><i class="uil uil-exclamation-octagon me-3"></i>فشل في عملية الدفع
                    </h5>
                </div>
                <div class="card-body">
                    <h5 class="card-title mt-0">لم يتم الدفع </h5>
                    <p class="card-text">يرجي التاكد من حسابك او الذهاب للمحاولة مرة اخري</p>
                    <a href="/payment" class="btn btn-primary">المحاولة مرة اخري </a>
                </div>
            </div>
        </div>

        <div class="col-lg-4" *ngIf="isPaid">
            <div class="card border border-success">
                <div class="card-header bg-transparent border-success">
                    <h5 class="my-0 text-success"><i class="uil uil-check-circle me-3"></i>تم الدفع بنجاح</h5>
                </div>
                <div class="card-body">
                    <h5 class="card-title mt-0">شكرا لك </h5>
                    <p class="card-text">يمكنك الان استخدام جميع خدمات البرنامج</p>
                    <a href="/pages/new" class="btn btn-primary">انششاء توثيق </a>
                </div>
            </div>
        </div>
    </div>
</div>