import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private auth: AuthService,
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.auth.isAuthenticated) {
			// this.auth.currentUserSubject.subscribe(res =>{
			// 	if(!res.paid){
			// 		console.log(state.url);
			// 		if(state.url != "/payment"){
			// 			this.router.navigate(['payment']);
			// 			return true;
			// 		}
			// 	}
			// });
			return true;
		}
		// not logged in so redirect to login page with the return url
		this.auth.logout();
		return false;
	}
}