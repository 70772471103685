<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">

  <!-- LOGO -->
  <div class="navbar-brand-box">
    <a [routerLink]="['/']" class="logo logo-dark">
      <span class="logo-sm">
        <img src="assets/images/logo-sm.png" alt="" height="22">
      </span>
      <span class="logo-lg">
        <img src="assets/images/logo-dark.png" alt="" height="20">
      </span>
    </a>

    <a [routerLink]="['/']" class="logo logo-light">
      <span class="logo-sm">
        <img src="assets/images/logo-sm.png" alt="" height="22">
      </span>
      <span class="logo-lg">
        <img src="assets/images/logo-light.png" alt="" height="20">
      </span>
    </a>
  </div>
  <button type="button" class="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
    (click)="toggleMobileMenu($event)">
    <i class="fa fa-fw fa-bars"></i>
  </button>
  <ngx-simplebar class="h-100 sidebar-menu-scroll" #componentRef *ngIf="!isCondensed">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </ngx-simplebar>

  <ngx-simplebar class="sidebar-menu-scroll" #componentRef *ngIf="isCondensed">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </ngx-simplebar>


</div>

<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <ng-container *ngFor="let item of menuItems">
        <li *ngIf="!item.isTitle && !item.isLayout">

          <a [routerLink]="item.link" *ngIf="!item.isExternal" class="side-nav-link-ref" routerLinkActive="active">
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate}}</span>
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text |
              translate}}</span>
          </a>
          <a [href]="item.link" target="_blank" *ngIf="item.isExternal" class="side-nav-link-ref"
            routerLinkActive="active">
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate}}</span>
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text |
              translate}}</span>
          </a>

        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<!-- Left Sidebar End -->