import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  headers = new HttpHeaders({ "content-type": "application/json" });
  options = { headers: this.headers, withCredintials: false };
  constructor(private http: HttpClient,private sessionService:SessionService) { }
  post(serviceName: string, data: any, token?: string) {
    let header;
    // debugger;
    if (token) {
      let authHeader = new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "accept/json",
        Authorization: `Bearer ${token}`,
      });

      header = { headers: authHeader, withCredintials: true };
    } else {
      header = this.options;
    }
    const url = environment.apiUrl + serviceName;
    return this.http.post(url, JSON.stringify(data), header);
  }
  get(serviceName: string, data: any, token?: boolean) {
    const url = environment.apiUrl + serviceName;
    let header;
    // debugger;
    if (token) {      
      header = {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      header = this.options;
    }
    return this.http.get(url, header);
  }
}
