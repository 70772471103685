import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from './../models/user.models';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private router:Router) { }
  loggedUser:User;
  currentPage:string;
  sessionUpdated:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);
  
  getUser():User{
    try {
        // debugger;
        if(localStorage.currentUser)
        {
            return JSON.parse(localStorage.currentUser);
        }
        else
        {
            // navigate to login 
        }
    } catch (error) {
        // debugger;
        // this.router.navigateByUrl("/login")
        
    }
    return null;
  }
  getFullName():string{
      try {
          if(localStorage.currentFullName)
          {
              return localStorage.currentFullName;
          }
          else
          {
              // navigate to login 
          }
      } catch (error) {
          // debugger;
          // this.router.navigateByUrl("/login")
          
      }
      return null;
  }
  getToken():string{
      try {
          if(localStorage.token)
          {
              // debugger;
              return localStorage.token;
          }
          else
          {
              // navigate to login 
          }
      } catch (error) {
          // debugger;
          // this.router.navigateByUrl("/login")
          
      }
      return null;
  }
  getNeedReload():boolean{
      try{
          return localStorage.needReload;
      }
      catch(e){

      }
      return null;
  }
  logout(){
      // localStorage.clear();
      localStorage.removeItem("currentUser");
      localStorage.removeItem("token");
      this.removeReloadAfterLogin();
  }
  setUser(value:User,token:string)
  {
      // debugger;
      if(value)
      {
          this.loggedUser=  value;
          localStorage.currentUser=JSON.stringify(value);
          this.setToken(token);
          this.needReloadAfterLogin();
      }
  }
  updateUserData(value:User,token:string)
  {
      if(value)
      {
          this.loggedUser=  value;
          localStorage.currentUser=JSON.stringify(value);
          this.setToken(token);
          this.needReloadAfterLogin();
      }
  }
  sessionExpired(){
    this.logout();
    this.router.navigateByUrl("/account/login").then(() => {
        // location.reload();
    });
  }
  updateUser(value:User)
  {
      // debugger;
      if(value)
      {
          this.loggedUser=  value;
          localStorage.currentUser=JSON.stringify(value);
      }
  }
  setToken(token){
      localStorage.token=token;

  }
  needReloadAfterLogin(){
      localStorage.needReload=true;
  }
  removeReloadAfterLogin(){
      localStorage.removeItem('needReload');
  }
}
