import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private auth: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// add default header values fo reach request
		let headers = {};
		const currentUser = this.auth.currentUserValue;
		if (currentUser) {
			headers['Authorization'] = `Bearer ${currentUser.JWT}`;
		}
		
		request = request.clone({
			setHeaders: headers
		});
		return next.handle(request);
	}
}