import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'نموذج توثيق برنامج',
        icon: 'uil-file',
        link: '/pages/new',
    },
    {
        id: 2,
        label: 'النماذج السابقة',
        icon: 'uil-archive',
        link: '/pages/reports',
    },
    {
        id: 3,
        label: 'المناسبات',
        icon: 'uil-calendar-alt',
        link: '/pages/occasions',
    },
    {
        id: 4,
        label: 'جدول الحصص',
        icon: 'uil-window-section',
        link: '/pages/classes',
    },
    
];

