import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	constructor(
		private http: HttpClient,
		private toastr: ToastrService 
	){}
	post(route: string, params: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.http.post<any>(`${environment.apiUrl}/${route}`, params).toPromise().then(res=> {
				if (_.has(res, 'msg')){
					if (res.msg.show) {
						if(res.msg.type == 'success') {
							this.toastr.success('', res.msg.text);
							resolve(res.data);
						}
						else {
							this.toastr[res.msg.type]('', res.msg.text);
							reject(res.msg.text);
						}
					}
				}
				else {
					resolve(res.data);
				}
			}, error=> {
				if (!environment.production) {
					console.log(error);
					this.toastr.error('', 'Server error'); //check network or log for real error
				}
				reject(error);
			});
		});
	}
	get(route: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.apiUrl}/${route}`).toPromise().then(res=> {
				if (_.has(res, 'msg')){
					if (res.msg.show) {
						if(res.msg.type == 'success') {
							this.toastr.success('', res.msg.text);
							resolve(res.data);
						}
						else {
							this.toastr[res.msg.type]('', res.msg.text);
							reject(res.msg.text);
						}
					}
				}
				else {
					resolve(res.data);
				}
			}, error=> {
				if (!environment.production) {
					console.log(error);
					this.toastr.error('', 'Server error'); //check network or log for real error
				}
				reject(error);
			});
		});
	}
}